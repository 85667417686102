<template>
  <b-container class="previous-lessons-container nopadding relative">
    <h3>Previous Lessons</h3>
    <b-table
      hover
      show-empty
      emptyText="No plans to show"
      :busy="isBusy"
      :items="previousLessons"
      :fields="fields"
      class="mt-3"
      ref="table">
      <template v-slot:table-busy>
        <div class="text-danger my-2 text-center">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import * as momentTemp from 'moment';

const moment = momentTemp.default;

export default {
  name: 'PreviousLessonsTable',
  props: {
    isBusy: {
      required: true,
      type: Boolean,
    },
    previousLessons: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'start_time',
          label: 'Starting Date',
          sortable: true,
          formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
        },
        {
          key: 'duration',
          label: 'Duration (min)',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            const startDate = moment(item.start_time);
            const endDate = moment(item.end_time);
            const diff = endDate.subtract(startDate);
            return diff.minutes();
          },
        },
        {
          key: 'state',
          label: 'State',
          sortable: true,
        },
        {
          key: 'total_students',
          label: 'Nº Students',
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
  h3 {
    text-transform: none;
  }

  .nopadding {
    padding: 0;
  }

  .relative {
    position: relative;
  }

  .previous-lessons-container {
    margin-top: 25px;
  }
</style>
