<template>
  <b-container class="nopadding relative">
    <h3 v-show="title">{{title}}</h3>
    <router-link id="add-plan"
    :to="{ name: 'Payments' }"
    class="button small special"
    v-show="showAddPlanButton">Add New Plan</router-link>
    <router-link id="add-plan"
    :to="{ name: 'Payments' }"
    class="button small special mobile"
    v-show="showAddPlanButton">
      <i class="fas fa-plus"></i>
    </router-link>
    <div class="table-responsive mt-3">
      <b-table
        hover
        show-empty
        emptyText="No plans to show"
        :busy="isBusy"
        :items="plans"
        :fields="fields"
        :tbody-tr-class="rowClass"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
        class="all-plans"
        ref="table">
        <template v-slot:table-busy>
          <div class="text-danger my-2 text-center">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <b-modal
    id="modal-show-plan"
    ref="mymodal"
    :title="selectedPlan.pretty_name"
    hide-footer
    @shown="onModalShown"
    @hidden="onModalHide">
      <form>
        <div class="form-group row">
          <label for="available_credits" class="col-sm-5 col-form-label">Available Credits</label>
          <div class="col-sm-7">
            <p id="available_credits">{{ selectedPlan.available_credits }}</p>
          </div>
        </div>
        <div class="form-group row">
          <label for="type" class="col-sm-5 col-form-label">Type</label>
          <div class="col-sm-7">
            <p id="type">{{ selectedPlan.type | capitalize }}</p>
          </div>
        </div>
        <div class="form-group row" v-show="selectedPlan.type == 'subscription'">
          <label for="active" class="col-sm-5 col-form-label">Membership</label>
          <div class="col-sm-7">
            <p id="active">{{ selectedPlan.is_active ? 'Active' : 'Cancelled' }}</p>
          </div>
        </div>
        <div class="form-group row">
          <label for="price" class="col-sm-5 col-form-label">Price</label>
          <div class="col-sm-7">
            <p id="price">{{ '£' + selectedPlan.price / 100 }}</p>
          </div>
        </div>
        <div class="form-group row" v-if="selectedPlan.type == 'subscription'">
          <label for="enrollDate" class="col-sm-5 col-form-label">Enrolled Date</label>
          <div class="col-sm-7">
            <p id="enrollDate">
            {{ selectedPlan.creation_date &&
            selectedPlan.creation_date | moment('DD/MM/YYYY') }}</p>
          </div>
        </div>
        <div class="form-group row" v-else>
          <label for="payedDate" class="col-sm-5 col-form-label">Payed Date</label>
          <div class="col-sm-7">
            <p id="enrollDate">{{ selectedPlan.payed_date &&
            selectedPlan.payed_date | moment('DD/MM/YYYY') }}</p>
          </div>
        </div>
        <div class="form-group row" v-show="selectedPlan.type == 'subscription'">
          <label for="nextRenewDate" class="col-sm-5 col-form-label">Next Renew Date</label>
          <div class="col-sm-7">
            <p id="nextRenewDate">{{ selectedPlan.next_renew_date &&
            selectedPlan.next_renew_date | moment('DD/MM/YYYY') }}</p>
          </div>
        </div>
        <div class="form-group row">
          <label for="expirationDate" class="col-sm-5 col-form-label">Expiration Date</label>
          <div class="col-sm-7">
            <p id="expirationDate">{{ selectedPlan.expiration_date &&
            selectedPlan.expiration_date | moment('DD/MM/YYYY') }}</p>
          </div>
        </div>
        <div class="form-group row" v-show="selectedPlan.type == 'subscription'">
          <label for="renewHistory" class="col-sm-5 col-form-label">Renew History</label>
          <div class="col-sm-7">
            <p v-show="!selectedPlan.renew_history">None</p>
            <p v-for="item in selectedPlan.renew_history" :key="item.message">
              {{ item && item.split | moment('DD/MM/YYYY') }}
            </p>
          </div>
        </div>
        <div v-show="error" class="alert alert-danger">{{ error }}</div>
      </form>
      <footer class="modal-footer">
        <span
          :title="!selectedPlan.can_cancel ? 'Minimum 11 lessons before being able to cancel' : ''"
          style="display: inline-block;">
          <button class="button small"
          @click="showConfirmation"
          v-show="selectedPlan.type == 'subscription'"
          :disabled="!selectedPlan.can_cancel">Cancel Subscription</button>
        </span>
        <button class="button special small" @click="hideModal">Ok</button>
      </footer>
    </b-modal>
    <b-modal
    size="sm"
    title="Cancel Subscription"
    ref="cancelConfirmation"
    hide-footer>
      <p>
        Are you sure do you want to cancel the
        <i>{{selectedPlan.pretty_name}}</i> subscription plan?
      </p>
      <footer class="modal-footer">
        <button class="button small"
        @click="hideConfirmation">No</button>
        <button class="button small special"
        @click="cancelSubscription">Yes</button>
      </footer>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PlansApi from '../../../api/Plans';

export default {
  name: 'PlansTable',
  props: {
    showAddPlanButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    plans: {
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    const self = this;
    return {
      error: '',
      plansOptions: [
        'Subscriptions',
        'Packs',
        'Offers',
      ],
      selectedPlan: {},
      fields: [
        {
          key: 'pretty_name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Plan type',
          sortable: true,
          formatter(value) {
            return self.$options.filters.capitalize(value);
          },
        },
        {
          key: 'price',
          label: 'Amount Payed (£)',
          sortable: true,
          formatter(value) {
            const amount = value / 100;
            return amount || '-';
          },
        },
        {
          key: 'next_renew_date',
          label: 'Next Renew Date',
          sortable: true,
          formatter(value, key, item) {
            if (item.type !== 'subscription' || value === null) { return '-'; }
            return Vue.moment(value).format('DD/MM/YYYY');
          },
        },
      ],
    };
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') { return ''; }
      if (item.is_active && item.type === 'subscription') { return 'table-success'; }
      return '';
    },
    onRowSelected(items) {
      [this.selectedPlan] = items;
      if (!this.selectedPlan) {
        this.selectedPlan = {};
        return;
      }

      this.$refs.mymodal.show();
    },
    hideModal() {
      this.error = '';
      this.$refs.mymodal.hide();
      this.$refs.table.clearSelected();
    },
    showConfirmation() {
      this.error = '';
      this.$refs.cancelConfirmation.show();
    },
    hideConfirmation() {
      this.$refs.cancelConfirmation.hide();
    },
    cancelSubscription() {
      PlansApi
        .deleteSubscription(this.selectedPlan.id)
        .then((response) => {
          this.selectedPlan.is_active = response.data.is_active;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to cancel subscription. Please try again or contact us');
        });
      this.hideConfirmation();
    },
    onModalShown() {
      const modalEl = document.querySelector('#modal-show-plan');
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
};
</script>

<style scoped>
  h3 {
    text-transform: none;
  }

  .nopadding {
    padding: 0;
  }

  .relative {
    position: relative;
  }

  #add-plan.button {
    position: absolute;
    right: 0;
    top: 0;
  }

  #add-plan.button.mobile {
    display: none;
  }

  @media screen and (max-width: 439px) {
    #add-plan.button {
      display: none;
    }

    #add-plan.button.mobile {
      display: block;
    }
  }
</style>

<style>
  table.all-plans:hover {
    cursor: pointer;
  }
</style>
