<template>
  <b-modal
    id="modal-upload-profile-picture"
    title="Update Photo"
    hide-footer
    size="lg"
    ref="modal"
    @shown="onModalShown"
    @hidden="onModalHide">
    <div class="content">
      <clipper-basic
        :src="currentPicture"
        ref="clipper"
        bg-color="black"
        class="my-clipper"
        :init-width="100"
        :init-height="100"
        :ratio="1">
        <div class="placeholder" slot="placeholder">No image</div>
      </clipper-basic>
    </div>
    <div v-show="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <footer class="modal-footer">
      <button class="button delete small"
              @click="deletePhoto">Delete Photo</button>
      <clipper-upload
        ref="originalPicture"
        v-model="picture"
        accept="image/png,image/jpeg"
        @input="error = null"
        class="button small">Upload Photo</clipper-upload>
      <button class="button special small"
              @click="savePhoto">Save Photo</button>
    </footer>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { clipperBasic, clipperUpload } from 'vuejs-clipper';
import UserApi from '../../../api/User';
import types from '../../../store/types';

export default {
  name: 'UploadProfilePictureModal',
  data() {
    return {
      picture: null,
      clipper: null,
      error: null,
      hasReplacedPhoto: false,
    };
  },
  components: {
    clipperBasic,
    clipperUpload,
  },
  methods: {
    ...mapActions({
      updateProfilePicture: types.actions.user.UPDATE_PROFILE_PICTURE,
    }),
    deletePhoto() {
      this.error = null;
      if (this.$refs.originalPicture.file) {
        this.$refs.originalPicture.file = null;
        this.picture = this.currentUser.profile.original_picture;
      } else {
        UserApi
          .deleteProfilePicture()
          .then((response) => {
            const { original } = response.data;
            this.picture = original;
            this.updateProfilePicture(original, original);
          })
          .catch((error) => {
            this.error = this.parseError(error, 'Unable to delete profile picture');
          });
      }
    },
    savePhoto() {
      this.error = null;
      this.$refs.clipper.clip().toBlob((blob) => {
        const data = new FormData();
        data.append('image', blob, 'profile.png');
        const originalFile = this.$refs.originalPicture.file;
        if (originalFile) {
          data.append('original', originalFile, 'original.png');
        }
        UserApi
          .updateProfilePicture(data)
          .then((response) => {
            const { picture, original } = response.data;
            this.picture = picture;
            this.updateProfilePicture(picture, original);
            this.$refs.modal.hide();
          })
          .catch((error) => {
            this.error = this.parseError(error, 'Unable to save photo. Please try again');
          });
      });
    },
    onModalShown() {
      const modalEl = document.querySelector('#modal-upload-profile-picture');
      disableBodyScroll(modalEl);
    },
    onModalHide() {
      clearAllBodyScrollLocks();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
    currentPicture() {
      return this.picture ? this.picture : this.currentUser.profile.original_picture;
    },
  },
};
</script>

<style scoped>
  .placeholder {
    text-align: center;
    padding: 20px;
    background-color: lightgray;
  }

  .my-clipper {
    width: 100%;
    max-width: 300px;
    margin: auto;
  }

  .my-preview {
    max-height: 200px;
    max-width: 200px;
  }

  .content {
    background-color: black;
    margin-bottom: 15px;
  }

  footer .button.delete {
    position: absolute;
    left: 15px;
    border-color: transparent;
  }

  @media screen and (max-width: 462px) {
    footer {
      display: contents;
    }

    footer .button.delete {
      left: 0;
      position: inherit;
    }

    .button {
      width: 100%;
      margin: 0;
      margin-bottom: 15px;
    }

    footer .button:last-child {
      margin-bottom: 0;
    }
  }
</style>

<style>
  .vuejs-clipper-basic__zoom-area {
    border-radius: 50%;
  }
</style>
