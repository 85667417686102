<template>
  <div class="profile page">
    <b-modal id="modal-errorDefault" title="ERROR" ok-only>
      <p>{{ error }}</p>
    </b-modal>
    <div class="left-side">
      <div class="about-me container">
        <div class="row">
          <div class="my-picture">
            <div class="img-container">
              <img v-bind:src="currentUser.profile.picture"
                   alt="My profile picture">
              <div class="overlay-trigger"
                   @mouseenter="showOverlay=true"
                   @mouseleave="showOverlay=false"
                   v-b-modal.modal-upload-profile-picture></div>
              <upload-profile-picture-modal></upload-profile-picture-modal>
              <div v-show="showOverlay"
                   class="overlay">
                <div class="noselect">
                  <p>Update Photo</p>
                  <i class="fas fa-camera"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-lg">
                <editable-field
                :value="currentUser.profile.name"
                name="name"></editable-field>
                <h4 class="role">{{ currentUser.type | capitalize }}</h4>
                <a @click.prevent.stop="resetPassword"
                  class="noselect reset-password">Reset Password</a>
                <b-modal id="modal-resetPassword" title="Reset Password Request" hide-footer>
                  <p>{{ resetPasswordResp }}</p>
                  <footer class="modal-footer">
                    <button class="button" @click="hideResetPasswordModal">Ok</button>
                  </footer>
                </b-modal>
              </div>
              <div class="col-lg properties">
                <div v-if="currentUser.type === 'student'" class="row">
                  <div class="col-sm">
                    <h3>{{ attendedLessons }}</h3>
                    <p>Attended Lessons</p>
                  </div>
                  <div class="col-sm">
                    <h3>{{ scheduledLessons }}</h3>
                    <p>Scheduled Lessons</p>
                  </div>
                  <div class="col-sm">
                    <h3>{{ availableCredits }}</h3>
                    <p>Available Credits</p>
                  </div>
                </div>
                <div v-else-if="currentUser.type === 'teacher'" class="row">
                  <div class="col-sm">
                    <h3>{{ totalLessons }}</h3>
                    <p>Total Lessons</p>
                  </div>
                  <div class="col-sm">
                    <h3>{{ scheduledLessons }}</h3>
                    <p>Scheduled Lessons</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentUser.type === 'student'">
      <div class="active plans-table">
        <plans-table
        :plans="activePlans"
        :isBusy="isBusy"
        :showAddPlanButton="true"
        title="My Plans"></plans-table>
      </div>
      <div class="old plans-table">
        <plans-table
        :plans="oldPlans"
        :isBusy="isBusy"
        title="Old Plans"></plans-table>
      </div>
    </div>
    <div v-else-if="currentUser.type === 'teacher'">
      <div class="previous-lessons">
        <previous-lessons-table
          :previousLessons="previousLessons"
          :isBusy="isBusy"></previous-lessons-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserApi from '../../api/User';

import EventBus from '../../components/common/utils/event-bus';
import UploadProfilePictureModal from '../../components/common/profile/UploadProfilePictureModal.vue';
import PlansTable from '../../components/student/profile/PlansTable.vue';
import EditableField from '../../components/common/utils/EditableField.vue';
import PreviousLessonsTable from '../../components/teacher/profile/PreviousLessonsTable.vue';
import types from '../../store/types';

export default {
  name: 'Profile',
  data() {
    return {
      attendedLessons: 0,
      scheduledLessons: 0,
      availableCredits: 0,
      totalLessons: 0,
      previousLessons: [],
      showOverlay: false,
      error: null,
      plans: [],
      activePlans: [],
      oldPlans: [],
      isBusy: true,
      resetPasswordResp: null,
    };
  },
  components: {
    UploadProfilePictureModal,
    PlansTable,
    EditableField,
    PreviousLessonsTable,
  },
  methods: {
    ...mapActions({
      updateUserProfile: types.actions.user.UPDATE_PROFILE,
    }),
    resetPassword() {
      UserApi
        .resetPassword()
        .then((response) => {
          this.resetPasswordResp = response.data.success;
          this.$bvModal.show('modal-resetPassword');
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update name, please try again');
          this.$bvModal.show('modal-errorDefault');
        });
    },
    hideResetPasswordModal() {
      this.$bvModal.hide('modal-resetPassword');
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  created() {
    EventBus.$on('edited-name', (name) => {
      this.currentUser.profile.name = name;
      this.updateUserProfile(this.currentUser.profile);
      UserApi
        .updateProfile({ name })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update name, please try again');
          this.$bvModal.show('modal-errorDefault');
        });
    });

    UserApi
      .getProfile()
      .then(({ data }) => {
        this.updateUserProfile(data.profile);
        if (this.currentUser.type === 'student') {
          this.attendedLessons = data.attended_lessons;
          this.scheduledLessons = data.scheduled_lessons;
          this.availableCredits = data.available_credits;
          this.plans = data.profile.offers.concat(data.profile.subscriptions, data.profile.packs);
          this.plans.sort(this.sortByDate('payed_date', 'creation_date', false));
          this.activePlans = [];
          this.oldPlans = [];
          for (let i = 0; i < this.plans.length; i += 1) {
            const item = this.plans[i];
            if (item.available_credits > 0 || item.is_active) {
              this.activePlans.push(item);
            } else {
              this.oldPlans.push(item);
            }
          }
        } else if (this.currentUser.type === 'teacher') {
          this.totalLessons = data.total_lessons;
          this.scheduledLessons = data.scheduled_lessons;
          this.previousLessons = data.previous_lessons;
        }
        this.isBusy = false;
      })
      .catch((error) => {
        this.error = this.parseError(error, 'Unable to fetch user profile, please refresh the page');
      });
  },
};
</script>

<style scoped>
  a.reset-password {
    color: #f16f8b;
    cursor: pointer;
  }

  a.reset-password:hover {
    color: #f32853;
  }

  .about-me {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 15px;
  }

  .about-me h3, h4 {
    text-transform: none;
    margin-bottom: 0;
  }

  .about-me img {
    border-radius: 50%;
    max-width: 200px;
    max-height: 200px;
  }

  .about-me .my-picture .img-container {
    position: relative;
    width: 200px;
    height: 200px;
  }

  .about-me .my-picture .img-container > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  .about-me .my-picture .img-container .overlay {
    background-color: rgba(0,0,0,0.5);
    text-align: center;
  }

  .about-me .my-picture .img-container .overlay-trigger {
    cursor: pointer;
    z-index: 30;
    outline: none;
  }

  .about-me .my-picture .img-container .overlay > div {
    position: relative;
    top: 125px;
  }

  .about-me .my-picture .img-container .overlay > div * {
    color: #fff;
  }

  .about-me .my-picture .img-container .overlay p {
    margin: 0;
  }

  .about-me .my-picture .img-container .overlay i {
    font-size: 25px;
  }

  .about-me .role {
    font-weight: normal;
  }

  .about-me .properties {
    text-align: center;
    padding: 0;
  }

  .about-me .properties * {
    padding: 0;
  }

  .about-me .properties p {
    margin: auto;
  }

  .plans-table {
    margin-top: 25px;
  }

  @media screen and (max-width: 413px) {
    .about-me .properties .row {
      margin-left: 0;
    }

    .about-me .properties .row .col {
      padding: 0 15px;
    }

    .my-picture {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }

</style>
