<template>
  <div class="editable-field-container">
    <h3
    contenteditable="false"
    @blur.prevent.stop="onLoseFocus"
    @click.prevent.stop
    @focus.prevent.stop
    ref="editableField"
    class="editable-field">{{ value }}</h3>
    <h3>
      <i
      class="fas fa-pencil-alt special clickable"
      @click.prevent.stop="onEdit"
      ref="editButton"></i>
    </h3>
  </div>
</template>

<script>
import EventBus from './event-bus';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      preventEdit: false,
    };
  },
  methods: {
    onEdit() {
      if (this.preventEdit) { return; }
      this.toggleContentEditable();
    },
    onLoseFocus() {
      const field = this.toggleContentEditable();
      // Hacky fix to prevent Blur and then click again
      this.preventEdit = true;
      const self = this;
      setTimeout(() => {
        self.preventEdit = false;
      }, 100);
      // Emit edited event
      if (this.name !== field.innerText) {
        EventBus.$emit(`edited-${this.name}`, field.innerText);
      }
    },
    toggleContentEditable() {
      const { editableField, editButton } = this.$refs;
      editableField.contentEditable = editableField.contentEditable === 'true' ? 'false' : 'true';
      if (editableField.contentEditable === 'true') {
        editButton.classList.add('clicked');
        document.addEventListener('touch', this.onLoseFocus);
        document.addEventListener('click', this.onLoseFocus);
      } else {
        editButton.classList.remove('clicked');
        document.removeEventListener('touch', this.onLoseFocus);
        document.removeEventListener('click', this.onLoseFocus);
      }
      return editableField;
    },
  },
};
</script>

<style scoped>
  .editable-field-container {
    display: flex;
  }

  .editable-field {
    line-height: 2em;
    padding: 0 10px;
    left: -10px;
    position: relative;
  }

  h3 {
    margin-bottom: 0;
  }

  .editable-field.selected,
  [contenteditable='true'] {
    outline: 1px solid #f32853;
    border-radius: 5px;
  }

  /* Icons MySpanishNow Style */
  i.fas.special {
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    color: #f32853;
    position: relative;
  }

  i.fas.clickable {
    cursor: pointer;
  }

  i.fas.clickable.special:hover,
  i.fas.clickable.special.clicked {
    background-color: #f328532e;
  }

  @media screen and (max-width: 1680px) {
    i.fas.special {
      width: 39px;
      height: 39px;
    }
  }

  @media screen and (max-width: 980px) {
    i.fas.special {
      width: 42px;
      height: 42px;
    }
  }
</style>
