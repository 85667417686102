/**
 * Holds all the user API requests
 */
import Api from './Api';

const END_POINT = 'student/plans';

export default {
  /**
   * Retrieves all plans from the current logged in student
   */
  all() {
    return Api.get(END_POINT);
  },
  buyOffer(name) {
    return Api.post(`${END_POINT}/offer`, { name });
  },
  buyPack(name) {
    return Api.post(`${END_POINT}/pack`, { name });
  },
  buySubscription(name, paymentMethodId) {
    const data = {
      name,
      payment_method_id: paymentMethodId,
    };
    return Api.post(`${END_POINT}/subscription`, data);
  },
  deleteSubscription(lessonId, spinner = true) {
    return Api.delete(`${END_POINT}/subscription/${lessonId}`, {}, { spinner });
  },
};
